/*
 * @file Класс
 * @version 2022.2.6
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */

.App {
  text-align: center;
}

.error {
  color: red;
  font-weight: bold;
}

.game {
  width: 100%;
  max-width: 500px;
}

h1 {
  color: gold;
}

.witch-row {
  display: flex;
}

.state2 {
  border: 1px solid darkred;
}

.state0 {
  border: 0;
}

.state1 {
  border: 1px solid darkgreen;
}

.small {
  font-size: 0.5em;
  text-align: left;
}

.small a, .small a:visited, .small a:active {
  color: white;
  text-decoration: underline;
}

.witch {
  width: 100px;
}

.witch img {
  width: 100px;
}

.witch:hover {
  opacity: 0.5;
  animation: App-logo-spin infinite 10s linear;
}


.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
